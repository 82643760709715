<div class="back-to-home rounded d-none d-sm-block">
  <a routerLink="/index" class="btn btn-icon btn-primary">
    <i-feather name="arrow-left" class="icons"></i-feather>
  </a>
</div>

<!-- COMING SOON PAGE -->
<section class="bg-home d-flex align-items-center" data-jarallax='{"speed": 0.5}'
  style="background-image: url('assets/images/logo-only-black.png');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <a href="javascript:void(0)" class="logo h5"><img src="assets/images/logo-light.png" height="24" alt=""></a>
        <div class="text-uppercase title-dark text-white mt-2 mb-4 coming-soon">We're Coming soon...</div>
        <!--<p class="text-light para-desc para-dark mx-auto">Start working with <span
            class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
          awareness, drive traffic, connect.</p>-->
      </div>
    </div>
  </div> <!-- end container -->
</section>
<!--section end-->
<!-- COMING SOON PAGE -->