<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/Untitled-1.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title text-white title-dark mb-0">Contract Vehicles</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contract Vehicles</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">

  <div class="container">
    <div class="row">
        <div class="col-12">
            <h4 class="mb-4">Our Services</h4>
            <p class="text-muted h6 fw-normal">Get Start With <span class="text-primary fw-bold">Landrick.</span> Launch your campaign and benefit from our expertise on designing and managing conversion centered <a href="https://getbootstrap.com/" target="_blank" class="fw-bold text-primary">Bootstrap 5</a> angular page.</p>
            <p class="text-muted h6 fw-normal">Landrick is a Powerful Saas & Software Bootstrap Template. It is an excellent Angular template for startup, coworking space, cloud hosting, car ride, classic saas, classic application, event, business, application, educational course, personal portfolio, services, enterprise, minimal portfolio single product, saas, Social media marketing, Digital marketing/agency, Email Templates, Online Learning Course, marketing, agency, Careers, Customer Supports, Onepage Landing, and much more. Landrick is fully updated with the latest Bootstrap v5.1.3 and Gulp file. We are provide a Dark version with RTL supported in both the Light and Dark versions.</p>
        </div>
    </div>
</div>

<br>
<br>
<div class="container ">
  <div class="row align-items-center">
    <div class="col-lg-7 col-md-6">
      <div class="faq-content me-lg-5">
        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
          <ngb-panel cardClass="border-0 rounded shadow mb-2">
            <ng-template ngbPanelTitle>
              <div class="border-0 bg-light" id="headingtwo">
                <h6 class="title mb-0"> How does it work ? </h6>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p class="text-muted mb-0 faq-ans"> There are many variations of passages of Lorem Ipsum available, but
                the majority have suffered alteration in some form.
              </p>
            </ng-template>
          </ngb-panel>
          <ngb-panel cardClass="border-0 rounded shadow mb-2">
            <ng-template ngbPanelTitle>
              <div class="border-0 bg-light" id="headingtwo">
                <h6 class="title mb-0 "> Do I need a designer to use Landrick ? </h6>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                the majority have suffered alteration in some form.</p>
            </ng-template>
          </ngb-panel>
          <ngb-panel cardClass="border-0 rounded shadow mb-2">
            <ng-template ngbPanelTitle>
              <div class="border-0 bg-light" id="headingtwo">
                <h6 class="title mb-0"> What do I need to do to start selling ? </h6>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                the majority have suffered alteration in some form.</p>
            </ng-template>
          </ngb-panel>
          <ngb-panel cardClass="border-0 rounded shadow mb-2">
            <ng-template ngbPanelTitle>
              <div class="border-0 bg-light" id="headingtwo">
                <h6 class="title mb-0"> What happens when I receive an order ?
                </h6>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available, but
                the majority have suffered alteration in some form.</p>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <!--end col-->

    <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <img src="" alt="">
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>


  
</section>
<!--end section-->
<!-- Project End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->