<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/Untitled-1.png') center center;">
    <div class="bg-overlay"></div>
    <div class="container">
      <div class="row mt-5 justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="pages-heading">
            <h4 class="title text-white title-dark mb-0">About us </h4>
              </div>
          </div>  <!--end col-->
      </div><!--end row-->
      
      <div class="position-breadcrumb">
          <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">About</li>
              </ul>
          </nav>
      </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Documentation Start -->
<section class="section">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <h4 class="mb-4">OUR STORY</h4>
              <p class="text-muted h6 fw-normal">     

                Data Fueled Solutions, LLC is a Joint Venture (JV) between
                <a href="https://www.linkedin.com/company/2627020/admin/" class="text-primary " target="_blank">BNL Consulting</a>
                (
                <a href="https://www.sba.gov/brand/assets/sba/resource-partners/hubzone-fact-sheet.pdf" class="text-primary " target="_blank">HUBZone-certified</a>
                 ) and 
                <a href="https://www.zoominfo.com/c/timehri-solutions/540164076" class="text-primary " target="_blank"> Timehri Solutions </a>
                  (
                  <a href="https://www.sba.gov/federal-contracting/contracting-assistance-programs/8a-business-development-program " class="text-primary" target="_blank">8(a)</a>
                   and 
                   <a href="https://business.defense.gov/Socioeconomic-Programs/SDVOSB/" class="text-primary " target="_blank">Service Disabled Veteran Owned-verified</a>
                   ).  These two companies share a relationship that stretches over a decade and includes a history of splitting resources and working 
                   shoulder-to-shoulder on successful federal projects. Through our highly skilled and experienced staff, we can guarantee the provisions
                   and expectations below for our clients.

              <h4 class="mt-5"></h4>

              <h5 class="mt-4">Our Commitment</h5>
              <p class="text-muted mb-0"></p>

              <ul class="list-unstyled mt-3">
                  <li class="d-flex mt-2">
                      <i class="mdi mdi-arrow-right"></i>
                      <div class="ms-2">
                         <!-- <h6>Nodejs</h6>-->
                          <p class="text-muted">We provide creative and intelligent solutions to complex problems, improving client productivity and efficiency.
                          </p>
                      </div>
                  </li>

                  <li class="d-flex mt-2">
                      <i class="mdi mdi-arrow-right"></i>
                      <div class="ms-2">
                         <!-- <h6>Git</h6>-->
                          <p class="text-muted">We build strong client relationships based on performance, excellent service, and an enhanced experience.

                          </p>
                      </div>
                  </li>

                  <li class="d-flex mt-2">
                    <i class="mdi mdi-arrow-right"></i>
                    <div class="ms-2">
                       <!-- <h6>Git</h6>-->
                        <p class="text-muted">We maintain contract integrity through a management approach based on sound methodologies such as Agile and a proactive policy on risk mitigation.

                        </p>
                    </div>
                </li>
              </ul>
          </div>
      </div>
  </div>
</section>

    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
      [ngxScrollTo]="'#home'">
      <i-feather name="arrow-up" class="icons"></i-feather>
    </a>
    <!-- Back to top -->