<!-- Hero Start -->
<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/Untitled-1.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title text-white title-dark mb-0">Services</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Services</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">

  <div class="container">
    <div class="row">
        <div class="col-12">
            <h4 class="mb-4">Our Services</h4>
            <p class="text-muted h6 fw-normal">Get Start With <span class="text-primary fw-bold">Landrick.</span> Launch your campaign and benefit from our expertise on designing and managing conversion centered <a href="https://getbootstrap.com/" target="_blank" class="fw-bold text-primary">Bootstrap 5</a> angular page.</p>
            <p class="text-muted h6 fw-normal">Landrick is a Powerful Saas & Software Bootstrap Template. It is an excellent Angular template for startup, coworking space, cloud hosting, car ride, classic saas, classic application, event, business, application, educational course, personal portfolio, services, enterprise, minimal portfolio single product, saas, Social media marketing, Digital marketing/agency, Email Templates, Online Learning Course, marketing, agency, Careers, Customer Supports, Onepage Landing, and much more. Landrick is fully updated with the latest Bootstrap v5.1.3 and Gulp file. We are provide a Dark version with RTL supported in both the Light and Dark versions.</p>
        </div>
    </div>
</div>
  <div class="container mt-100 mt-60">

    <!--end row-->

    <div class="row justify-content-center">
        <div class="col-lg-6 mt-2">
            <div class="px-md-4 pt-4 text-center">
                <h4 class="mb-3">Individual Services</h4>
                <p class="text-muted mb-0">It is said that song composers of the past used dummy texts as lyrics
                    when writing melodies in order.</p>

                <div class="position-relative mt-5">
                    <div class="bg-overlay bg-primary bg-gredient rounded-md" style="opacity: 0.6;"></div>
                    <img src="assets/images/finance/1.jpg" class="rounded-md shadow img-fluid" alt="">
                </div>

                <div class="px-md-4 pt-4 text-start">
                    <div class="d-flex">
                        <i class="uil uil-bullseye h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Engaging Group Discussion​</h5>
                            <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                realistic so that the layout impression</p>
                        </div>
                    </div>

                    <div class="d-flex mt-4">
                        <i class="uil uil-create-dashboard h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Software Engineering​</h5>
                            <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                realistic so that the layout impression</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end col-->

        <div class="col-lg-6 mt-2">
            <div class="px-md-4 pt-4 text-center">
                <h4 class="mb-3">Organization Services</h4>
                <p class="text-muted mb-0">It is said that song composers of the past used dummy texts as lyrics
                    when writing melodies in order.</p>

                <div class="position-relative mt-5">
                    <div class="bg-overlay bg-primary bg-gredient rounded-md" style="opacity: 0.6;"></div>
                    <img src="assets/images/finance/2.jpg" class="rounded-md shadow img-fluid" alt="">
                </div>

                <div class="px-md-4 pt-4 text-start">
                    <div class="d-flex">
                        <i class="uil uil-bullseye h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Engaging Group Discussion​</h5>
                            <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                realistic so that the layout impression</p>
                        </div>
                    </div>

                    <div class="d-flex mt-4">
                        <i class="uil uil-create-dashboard h5 text-primary"></i>
                        <div class="flex-1 ms-2">
                            <h5>Software Engineering​</h5>
                            <p class="mb-0 text-muted">It is advantageous when the dummy text is relatively
                                realistic so that the layout impression</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end col-->
    </div>
    <!--end row-->
</div>

  <!-- Documentation Start -->
  <div class="container  mt-100 mt-60">
    <div class="row">
        <div class="col-12">
            <h4 class="mt-5">Setup Angular</h4>

            <p class="text-muted mb-0">Please follow below steps to install and setup all prerequisites:</p>

            <ul class="list-unstyled mt-3">
                <li class="d-flex mt-2">
                    <i class="mdi mdi-arrow-right"></i>
                    <div class="ms-2">
                        <h6>Nodejs</h6>
                        <p class="text-muted">Make sure to have the <a href="https://nodejs.org/" class="text-primary fw-bold" target="_blank">Node.js</a> installed & running in your computer. If you already have installed Node on your computer, you can skip this step if your existing node version is greater than 18. We suggest you to use LTS version of Node.js.</p>
                    </div>
                </li>

                <li class="d-flex mt-2">
                    <i class="mdi mdi-arrow-right"></i>
                    <div class="ms-2">
                        <h6>Git</h6>
                        <p class="text-muted">Make sure to have the <a href="https://git-scm.com/" class="text-primary fw-bold" target="_blank">Git</a> installed globally & running on your computer. If you already have installed git on your computer, you can skip this step.</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
</section>
<!--end section-->
<!-- Project End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->