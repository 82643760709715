

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/index" *ngIf="navClass !== 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark logo-light-mode" height="24" alt="">
        <img src="assets/images/logo-light.png" class="logo-dark-mode" height="24" alt="">
      </a>

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>


      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="24" alt="">
        <!--<img src="assets/images/logo-light.png" class="l-light" height="24" alt="">-->
      </a>
    </div>


    <div id="navigation" *ngIf="Menuoption == 'center'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li><a routerLink="/page-aboutus" class="nav-link-ref">About</a></li>
        <!--<li><a routerLink="/page-contract-vehicles" class="nav-link-ref">Contract Vehicles</a></li>-->
        <!--<li><a routerLink="/" class="nav-link-ref">Our Capabilities</a></li>-->
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Capabilities</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" [routerLink]='"/index"' [fragment]='"ourservices"'>Services</a></li>           
           <!-- <li><a class="nav-link-ref" routerLink="/">Possible Other Capability</a></li>-->
           <!-- <li><a class="nav-link-ref" routerLink="/">Possible Other Capability</a></li>-->
          </ul>
        </li>
       <!-- <li><a routerLink="/page-partnerships" class="nav-link-ref">Partnerships</a></li>-->
        <li><a routerLink="/page-contact-one" class="nav-link-ref">Contact</a></li>
      </ul>
      <!--end navigation menu-->
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->

    <div id="navigation" *ngIf="Menuoption == 'hosting'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li><a routerLink="/page-aboutus" class="nav-link-ref">About</a></li>
        <!--<li><a routerLink="/page-contract-vehicles" class="nav-link-ref">Contract Vehicles</a></li>-->
        <!--<li><a routerLink="/" class="nav-link-ref">Our Capabilities</a></li>-->
       <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Capabilities</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" [routerLink]='"/index"' [fragment]='"ourservices"'>Services</a></li>
           <!-- <li><a class="nav-link-ref" routerLink="/">Possible Other Capability</a></li>-->
           <!-- <li><a class="nav-link-ref" routerLink="/">Possible Other Capability</a></li>-->
          </ul>
        </li>
        <!--<li><a routerLink="/page-partnerships" class="nav-link-ref">Partnerships</a></li>-->
        <li><a routerLink="/page-contact-one" class="nav-link-ref">Contact</a>       
        
        
        </li>
      </ul>
      <!--end navigation menu-->
      <!--end login button-->
      <!--end login button-->
    </div>

  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header p-4 border-bottom">
    <h5 id="offcanvasRightLabel" class="mb-0">
      <img src="assets/images/logo-dark.png" height="24" class="light-version" alt="">
      <img src="assets/images/logo-light.png" height="24" class="dark-version" alt="">
    </h5>
    <button type="button" class="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas"
      aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="uil uil-times fs-4"></i></button>
  </div>
  <div class="offcanvas-body p-4">
    <div class="row">
      <div class="col-12">
        <img src="assets/images/contact.svg" class="img-fluid d-block mx-auto" style="max-width: 256px;" alt="">
        <div class="card border-0 mt-5" style="z-index: 1">
          <div class="card-body p-0">
            <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Subject</label>
                    <div class="form-icon position-relative">
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                        placeholder="Message :"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid">
                    <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas-footer p-4 border-top text-center">
    <ul class="list-unstyled social-icon social mb-0 d-flex justify-content-center gap-1">
      <li class="list-inline-item mb-0"><a href="https://1.envato.market/landrick" target="_blank" class="rounded"><i
            class="uil uil-shopping-cart align-middle" title="Buy Now"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://dribbble.com/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-dribbble align-middle" title="dribbble"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.behance.net/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-behance align-middle" title="behance"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.facebook.com/shreethemes" target="_blank"
          class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.instagram.com/shreethemes/" target="_blank"
          class="rounded"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://twitter.com/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-twitter align-middle" title="twitter"></i></a></li>
      <li class="list-inline-item mb-0"><a href="mailto:support@shreethemes.in" class="rounded"><i
            class="uil uil-envelope align-middle" title="email"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://shreethemes.in" target="_blank" class="rounded"><i
            class="uil uil-globe align-middle" title="website"></i></a></li>
    </ul><!--end icon-->
  </div>
</ng-template>
<!-- Offcanvas End -->