import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Usermessage } from 'src/app/shared/models/usermessage';
import { ContactInfoService } from 'src/app/shared/services/contact-info.service';

@Component({
  selector: 'app-page-contact-one',
  templateUrl: './page-contact-one.component.html',
  styleUrls: ['./page-contact-one.component.css']
})

/**
 * Page Contact-One Component
 */
export class PageContactOneComponent implements OnInit {

      // Set Topbar Option
  Menuoption = 'center';
  footerVariant = true;
  Settingicon = true;
  navClass = "nav-light";
  //contactInfoService: any;
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private contactInfoService: ContactInfoService) { }


//************************************************************** */
contactForm = this.formBuilder.group({
  name: '',
  email: '',
  message: ''
});

error = '';
usermessages: Usermessage[];
successMessage = '';
failureMessage = '';
warningMessage = '';

onSubmit(): void {
  var usermessage = this.contactForm.value;
  this.resetErrors();

  this.contactInfoService.store(usermessage)
    .subscribe( (res: any) => {
     //console.log(res.result);
       if (res.result == 'success') {
        this.successMessage = 'Thank You! Your message has been sent';
        this.alertSuccess();
                  // Reset the form
                  this.contactForm.reset();
       }
       else if (res.result == 'warning') {
        this.warningMessage = 'Please correct request and resubmit';
        this.alertWarning();
       }
       else {
        this.failureMessage = 'Oops! Something went wrong and we couldnt send your message.';
        this.alertFailure();
       }
        // Reset the form
       // this.contactForm.reset();
      },
      (err) => this.error = err
    );
}

private resetErrors(){
  this.error   = '';
}


alertSuccess() {
  var divToShow = document.querySelector('.alert-box.success') as HTMLElement;
  this.fin(divToShow);
}
alertFailure() {
  var divToShow = document.querySelector('.alert-box.failure') as HTMLElement;
  this.fin(divToShow);
}
alertWarning() {
  var divToShow = document.querySelector('.alert-box.warning') as HTMLElement;
  this.fin(divToShow);
}

fin(divin:any){
    var i = 0;
    divin.style.display = 'inline';
    this.fadeIn(divin,i);
}
fadeIn(el,i) {
    var go = (i)=> {
        setTimeout( ()=>{ this.seto(el,i); } , i * 2000);
    };
    for ( i = 0 ; i<=1 ; i = i + 0.01) {
      go(i);
    }
   // setTimeout( ()=>{el.style.opacity = '0'}, 7000);
    setTimeout( ()=>{el.style.display = 'none'}, 7000);
}
seto(el,i){
    el.style.opacity = i;
}

//*************************************************************** */



  ngOnInit(): void {
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }
}
