<div >
    <ng-template #execute_block>
        <footer class="footer"
        style="background-image: url('assets/images/svg-map.svg'); background-repeat: no-repeat; background-position: center;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 py-lg-5">
                    <div class="footer-py-60 text-center">
                        <a href="javascript:void(0)" routerLink="/index" class="logo-footer">
                            <img src="assets/images/logo-light.png" height="32" alt="">
                        </a>
                        <p class="mt-4 para-desc mx-auto"><span>11002 Ascott View Lane, Silver Spring, Maryland 20901</span><br>
                            <span>info@datafueledsolutions.com</span><br>
                            <span>240-550-6978</span></p>

                            <div class="mt-4 pt-2">
                                <a href="javascript:void(0)" routerLink="/page-contact-one" class="btn btn-primary">Contact Us<i class="uil uil-angle-right-b"></i></a>
                            </div>
                        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                           <!--<li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather></a>
                            </li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather></a>
                            </li> -->
                            <li class="list-inline-item ms-1"><a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fdatafueledsol" target="_blank" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather></a>
                            </li>
                            <li class="list-inline-item ms-1"><a href="https://www.linkedin.com/company/data-fueled-solutions/about/?viewAsMember=true" target="_blank" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather></a>
                            </li>
                        </ul><!--end icon-->
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->

        <div class="footer-py-30 footer-bar bg-footer">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="text-center">
                            <p class="mb-0">©
                                {{year}} Data Fueled Solutions 
                                <a href="javascript:void(0)" class="text-reset"></a>.
                            </p>
                        </div>
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end container-->
        </div>
    </footer>
    </ng-template>

    <div *ngIf="!footerVariant;else execute_block">

    </div>
</div>