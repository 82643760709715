<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100"
  style="background: url('assets/images/Untitled-1.png') center center;" id="home">
  <!--<div class="bg-overlay bg-gradient-primary opacity-8"></div>-->
  <div class="bg-overlay bg-gradient-secondary opacity-8"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12">
        <div class="title-heading text-center">
          <h1 class="heading title-dark text-white mb-3"> Thoughtful . Intelligent . Data Driven</h1>
          <p class="para-desc para-dark mx-auto text-white">Comprehensive software development lifecycle support, advanced analytic solutions, and more...</p>
          <div class="mt-4 pt-2">
            <a (click)="onClick('ourservices')" class="btn btn-primary">Our Services<i class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- How It Work Start -->
<section class="section bg-light">
  <div class="container ">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/hosting/2.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">WHO WE ARE</h4>
          <!--<p class="text-muted">-->
            <p >
            The founders of 
            <a href="https://bnlconsulting.com/" class="text-primary " target="_blank">BNL Consulting</a>
            (
            <a href="https://www.sba.gov/federal-contracting/contracting-assistance-programs/hubzone-program" class="text-primary " target="_blank">HUBZone-certified</a>
             ) and 
            <a href="https://timehrisolutions.com/" class="text-primary " target="_blank"> Timehri Solutions </a>
              (
              <a href="https://www.sba.gov/federal-contracting/contracting-assistance-programs/8a-business-development-program " class="text-primary" target="_blank">8(a)</a>
               and 
               <a href="https://www.sba.gov/federal-contracting/contracting-assistance-programs/veteran-contracting-assistance-programs#section-header-14" class="text-primary " target="_blank">Service Disabled Veteran Owned-verified</a>
               )
             have joined forces to create a small business specializing in custom analytics platforms and seamless cloud migration services.</p>

         <!-- <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span></li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span></li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span></li>
          </ul> -->
          <a href="javascript:void(0)" routerLink="/page-aboutus" class="btn btn-primary mt-3">Read More <i
              class="uil uil-angle-right-b"></i></a>
            <br><br><br>
            <img src="assets/images/8(a) Certified.png" style="width:100px; margin-right:5px;" alt="">
            <img src="assets/images/HUBZone Certified.png" style="width:100px" alt="">
            <img src="assets/images/cve_completed_s.jpg" style="width:130px" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->


<!-- Start Services -->
<section id="ourservices" class="section">
  <div class="container pb-lg-4 mb-md-5 mb-4">
    <div class="row align-items-center mb-4">
      <div class="col-lg-9 col-md-8 text-sm-start">
        <div class="section-title">
          <h4 class="title mb-4">WHAT WE DO</h4>
          <p class="text-muted para-desc mb-0">We’ll design, develop, and manage your Federal Government Information Systems, 
            as well as any data analytics, data visualization, enterprise integration, and legacy system modernization you 
            can throw our way.
          </p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 text-sm-end pt-2 pt-sm-0">
        <a href="javascript:void(0)" routerLink="/page-contact-one" class="btn btn-outline-primary">Learn more <i class="uil uil-angle-right-b"></i></a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-desktop-cloud-alt h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>System Modernization</h5>
            <p class="text-muted">Migrate to the cloud, plan a system automation, or perform DevOps orchestration with tools like AWS, Kubernetes, Terraform, and more.
            </p>
         <!--   <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-servers h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>SAS Enablement</h5>
            <p class="text-muted">Deploy a new system, update legacy implementations, or manage a migration with our certified SAS Viya and 9.4 experts.</p>
         <!--   <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-database h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Analytics</h5>
            <p class="text-muted">Let us build the platform perfect for your complex data extraction, analysis, business intelligence modeling, research, and machine learning.
            </p>
        <!--    <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-cloud-database-tree h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Data Visualization</h5>
            <p class="text-muted">We’ll use your inputs to create slick mobile apps, interactive dashboards, custom visualizations, enterprise-wide applications with modern web tools like React, Angular, and much more. </p>
        <!--    <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-data-sharing h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Enterprise Integration</h5>
            <p class="text-muted">We bring together cutting-edge database systems to connect all manner of disparate systems and legacy technology.
            </p>
           <!-- <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-cloud-computing h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Cloud Enablement</h5>
            <p class="text-muted">Need help administering a cloud implementation, or migrating applications, infrastructures, or databases? We can manage your cloud technologies with ease and efficiency.
            </p>
           <!-- <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-file-check-alt h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Project Management and Agile Implementation
            </h5>
            <p class="text-muted">We offer comprehensive project management, delivery, and quality assurance with the added bonus of agile framework coaching, mentoring, and framework customizations.

            </p>
          <!--  <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-cog h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Development Security Operations (DevSecOps)
            </h5>
            <p class="text-muted">We can help automate and synchronize your IT operational dependencies, as well as perform security integration.
            </p>
       <!--     <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>
      
      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features feature-primary">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-layer-group h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Full Stack Software Development
            </h5>
            <p class="text-muted">Whether it’s a system prototyping, proof of concept, COTS customization, or other interface, we can take care of your database design anf development needs.
            </p>
         <!--   <a href="javascript:void(0)" class="text-primary">Read more <i class="uil uil-angle-right-b"></i></a>-->
          </div>
        </div>
      </div>

      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- End Services -->


<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- How It Work End -->


<!-- CTA Start -->
<section class="section bg-light" >
 
  <div class="bg-light"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-dark mb-4">Our Partners</h4>
         <!--<p class="text-dark-50 para-dark para-desc mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>--> 
            <!--<section class="section">-->
  <div class="container mt-100 mt-60">
    <div class="row">
        <div class="col-lg-2 col-md-6 col-12">
            <a href="javascript:void(0)">
                <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                    <img src="assets/images/sas.png" style = "max-height: 40px;" alt="">
                    <div class="flex-1 ms-3">
                        <h4 class="title mb-0 text-dark"></h4>
                        <p class="text-muted mb-0"></p>
                    </div>
                </div>
            </a>
        </div>
        <!--end col-->
  
        <div class="col-lg-2 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <a href="javascript:void(0)">
                <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                    <img src="assets/images/magpie-logo.png" style = "max-height: 40px;" alt="">
                    <div class="flex-1 ms-3">
                        <h4 class="title mb-0 text-dark"></h4>
                        <p class="text-muted mb-0"></p>
                    </div>
                </div>
            </a>
        </div>

        <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
          <a href="javascript:void(0)">
              <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img src="assets/images/cloudera.png" style = "max-height: 40px;" alt="">
                  <div class="flex-1 ms-3">
                      <h4 class="title mb-0 text-dark"></h4>
                      <p class="text-muted mb-0"></p>
                  </div>
              </div>
          </a>
      </div>

      <div class="col-lg-2 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <a href="javascript:void(0)">
            <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                <img src="assets/images/amazon.png" style = "max-height: 40px;" alt="">
                <div class="flex-1 ms-3">
                    <h4 class="title mb-0 text-dark"></h4>
                    <p class="text-muted mb-0"></p>
                </div>
            </div>
        </a>
    </div>
      
        <div class="col-lg-2 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <a href="javascript:void(0)">
                <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                    <img src="assets/images/ibm.png" style = "max-height: 40px;" alt="">
                    <div class="flex-1 ms-3">
                        <h4 class="title mb-0 text-dark"></h4>
                        <p class="text-muted mb-0"></p>
                    </div>
                </div>
            </a>
        </div>   

        <div class="col-lg-1 col-md-6 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
          <a href="javascript:void(0)">
              <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                  <img src="assets/images/adobe.png" style = "max-height: 40px;" alt="">
                  <div class="flex-1 ms-3">
                      <h4 class="title mb-0 text-dark"></h4>
                      <p class="text-muted mb-0"></p>
                  </div>
              </div>
          </a>
      </div>
        <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!--</section>-->
  <!--end section-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- CTA End -->




<!-- Price Start 
<section class="section">

</section>
end section-->

<!-- Price End -->

<!-- Testi Start 
  <section class="section bg-light">
    <div class="container">
      <div class="row align-items-center mb-4 pb-2">
        <div class="col-lg-6">
          <div class="section-title text-center text-lg-start">
            <h6 class="text-primary">Blog</h6>
            <h4 class="title mb-4 mb-lg-0">Reads Our Latest <br> News & Blog</h4>
          </div>
        </div>
    
  
        <div class="col-lg-6">
          <div class="section-title text-center text-lg-start">
            <p class="text-muted mb-0 mx-auto para-desc">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
          </div>
        </div>

      </div>

  
      <app-blog [blogData]="blogData"></app-blog>

    </div>

</section>

Testi End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->