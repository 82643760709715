<!-- Hero Start -->
<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/Untitled-1.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title text-white title-dark mb-0">Contact Us</h4>
        </div>
      </div> <!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contact</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section">
  <div class="container mt-80 mt-60 ">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card custom-form rounded border-0 shadow p-4">
          <!--<form method="post" name="myForm" onsubmit="return validateForm()"> -->
            <form id="contact-form" [formGroup]="contactForm" >
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input formControlName="name" name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input formControlName="email" name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                  </div>
                </div>
              </div>
              <!--end col-->

             <!--  <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject</label>
                  <div class="form-icon position-relative">
                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                    <input formControlName="subject" name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                  </div>
                </div>
              </div>
             end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Comments <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                    <textarea formControlName="message" name="comments" id="comments" rows="4" class="form-control ps-5"
                      placeholder="Message :"></textarea>
                  </div>
                </div>
              </div>
            </div>    
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <div class="alert-box success">{{successMessage}}</div>
                  <div class="alert-box failure">{{failureMessage}}</div>
                  <div class="alert-box warning">{{warningMessage}}</div>
                </div>
              </div>
              <!--end col-->
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" (click)="onSubmit()" id="submit" name="send" class="btn btn-primary">Send Message</button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4">Contact Details</h4>
          <!--<p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect.</p>-->
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Email</h6>
              <a href="mailto:contact@example.com" class="text-primary">info@datafueledsolutions.com</a>
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Phone</h6>
              <a href="tel:240-550-6978" class="text-primary">240-550-6978</a>
            </div>
          </div>

         <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0">Location</h6>

              <a  class="video-play-icon text-primary">11002 Ascott View Lane<br>Silver Spring, Maryland 20901</a>
              <!--<ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template> -->
            </div>
          </div> 
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>


  
  </section>
  <!--end container-->
  <!--end container-->

  <!--end container-->

<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->