import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Usermessage } from  '../models/usermessage';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})



export class ContactInfoService {
  baseUrl = "http://localhost:80";
  usermessages: Usermessage[];

  constructor(private http: HttpClient) { }



 store(usermessage: Usermessage): Observable<any> {
  let headers = new HttpHeaders();
  headers.append('Content-Type','application/json');
    return this.http.post(`assets/contact.php`, { data: usermessage })
      .pipe(map((res) => {
        //console.log(res);
        return res;
      }),
      catchError(this.handleError));
  }


 /*  getAll(): Observable<Usermessage[]> {
    return this.http.get(`${this.baseUrl}/list`).pipe(
      map((res) => {
        this.usermessages = res['data'];
        return this.usermessages;
    }),
    catchError(this.handleError));
  }

  update(car: Car): Observable<Car[]> {
    return this.http.put(`${this.baseUrl}/update`, { data: car })
      .pipe(map((res) => {
        const theCar = this.cars.find((item) => {
          return +item['id'] === +car['id'];
        });
        if (theCar) {
          theCar['price'] = +car['price'];
          theCar['model'] = car['model'];
        }
        return this.cars;
      }),
      catchError(this.handleError));
  }

  delete(id: number): Observable<Car[]> {
    const params = new HttpParams()
      .set('id', id.toString());

    return this.http.delete(`${this.baseUrl}/delete`, { params: params })
      .pipe(map(res => {
        const filteredCars = this.cars.filter((car) => {
          return +car['id'] !== +id;
        });
        return this.cars = filteredCars;
      }),
      catchError(this.handleError));
  }*/

  private handleError(error: HttpErrorResponse) {
    console.log(error);

    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }
  

}
