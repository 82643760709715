<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home" style="background: url('assets/images/Untitled-1.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="pages-heading">
          <h4 class="title text-white title-dark mb-0"> Partnerships </h4>
        </div>
      </div><!--end col-->
    </div><!--end row-->

    <div class="position-breadcrumb">
      <nav aria-label="breadcrumb" class="d-inline-block">
        <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
          <li class="breadcrumb-item"><a routerLink="/index">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Partnerships</li>
        </ul>
      </nav>
    </div>
  </div> <!--end container-->
</section><!--end section-->

<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">


      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title">
            <h4 class="title title-light text-black mb-4">Stop leaving money on the table.</h4>
            <p class="text-black-50 para-light para-desc mx-auto">Start working with Landrick that can provide everything you
              need to generate awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->


    <div class="row projects-wrapper">
      <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 business" *ngFor="let item of filterredImages">
        <div class="card blog border-0 work-container work-classic shadow rounded-md overflow-hidden">
          <img src="{{item.image}}" class="img-fluid work-image" alt="">
          <div class="card-body">
            <div class="content">
              <a href="javascript:void(0)" class="badge badge-link bg-{{item.variant}}">{{item.type}}</a>
              <h5 class="mt-3"><a routerLink="/page-case-detail" class="text-dark title">{{item.title}}</a></h5>
              <p class="text-muted">{{item.content}}</p>
              <a href="javascript:void(0)" class="text-primary h6">Read More <i
                  class="uil uil-angle-right-b align-middle"></i></a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->